import HeroIlustration from "@assets/bg-community-hero.svg";
import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, Button, Grid, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router";
import styled from "styled-components";
import useWeb3 from "../../hooks/useWeb3";
import EditShare from "./EditShare";

const Hero = ({ community, loading, backgroundHeroImage }) => {
  const { loginFlow, installMetamask, hasWeb3Provider } = useWeb3();
  const { authLoading } = useStoreState((state) => state.authentication);
  const { isMobile, isDesktop } = useStoreState((state) => state.media);
  const { confirmed } = useStoreState((state) => state.user.user);
  const referral_code = sessionStorage.getItem("referral-code");
  const { isAdmin, isOwner } = useStoreState((state) => state.community);
  const { publicSeasons } = useStoreState((state) => state.community);
  const { showModal } = useStoreActions((action) => action.modals);
  const navigate = useNavigate();
  const logo = !community._destroy_logo && community.logo_url;
  const cover_media = !community._destroy_cover_media && community.cover_media_url;
  const subheadline = community.subheadline;
  const thriveTheme = community.theme === "thrive" || community.theme === null;
  const subheadlineColor = thriveTheme ? "02Primary500" : "02Primary0";

  const backgroundHero = thriveTheme
    ? backgroundHeroImage
    : community.header_background_url
    ? `url(${community.header_background_url})`
    : community.header_background_color;

  const editHeader = () =>
    showModal({ modalName: "CommunityEditorModal", communityID: community.slug || community.id });

  const goToActiveSeason = () => {
    const activeSeason = publicSeasons[0];
    const seasonId = activeSeason?.slug || activeSeason.id;
    navigate(`/seasons/${seasonId}`);
  };

  const connectWallet = () => {
    if (!hasWeb3Provider) {
      installMetamask();
    } else {
      loginFlow(referral_code);
    }
  };

  return (
    <WrapperHero $background={backgroundHero}>
      {!loading && (
        <MainContainer>
          <HeroGrid $gap={isDesktop ? "8px" : "0"} $columns="auto 40%">
            <HeroText>
              <TextStyled $typography="heading/x-large" $color="02Primary0" $bold>
                {community.name}
              </TextStyled>
              {subheadline && (
                <Text $typography="body/large" $spacing="mt-3" $color={subheadlineColor} $extraBold>
                  {community.subheadline}
                </Text>
              )}
              {confirmed && !community.isPreview && publicSeasons.length > 0 && (
                <Button
                  onClick={goToActiveSeason}
                  $bgColor="02Primary0"
                  $spacing="mt-6 p-0"
                  $md-spacing="mt-5"
                  $sm-spacing="mt-5"
                  $borderRadius="40px"
                  $color="02Primary500"
                  $borderColor="02Primary0"
                  $hoverBackground="02PrimaryGrey100"
                  $hoverBorderColor="02PrimaryGrey100"
                  $hoverColor="02Primary500"
                  $maxWidth={isMobile ? "245px" : "241px"}
                  $heightSize="56px"
                  $typograhy="body/medium"
                  $bold
                >
                  View Ways to Contribute
                </Button>
              )}
              {!confirmed && (
                <Button
                  loading={authLoading}
                  $size="lg"
                  $bold
                  $height="56px"
                  $borderRadius="40px"
                  $bgColor="02Primary0"
                  $color="02Primary500"
                  $borderColor="02Primary0"
                  $hoverBackground="02PrimaryGrey100"
                  $hoverBorderColor="02PrimaryGrey100"
                  $hoverColor="02Primary500"
                  onClick={connectWallet}
                  $spacing="mt-6"
                  $maxWidth={!isMobile && "199px"}
                >
                  Connect Wallet
                </Button>
              )}
            </HeroText>

            <Box
              $height={logo && "100%"}
              $spacing={subheadline ? "mb-a ml-a" : "ml-a"}
              $gap="15px"
              $flex
              $alignItems="center"
              $relative
            >
              {" "}
              {!community.isPreview && (
                <EditShare
                  isAdmin={isAdmin || isOwner}
                  cover_media={cover_media}
                  editHeader={editHeader}
                  subheadline={subheadline}
                  logo={logo}
                />
              )}
              {cover_media !== null && <StyledImage src={cover_media}></StyledImage>}
              {!cover_media && logo !== null && <StyledLogo src={logo} />}
            </Box>
          </HeroGrid>
        </MainContainer>
      )}
    </WrapperHero>
  );
};

const WrapperHero = styled.div`
  background: ${({ theme, $background }) => theme.colors[$background] || $background};
  background-repeat: no-repeat;
  background-position-x: center;
  margin: 0;
  max-height: 410px;
  display: flex;
  min-height: 300px;
  @media ${breakpoints.desktop} {
    background-size: cover;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
    height: unset;
  }

  @media ${breakpoints.tablet} {
    height: auto;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
    border-radius: 0;
    min-height: 100px;
  }
`;

const HeroGrid = styled(Grid)`
  margin: 0 auto;

  @media ${breakpoints.tablet}, @media ${breakpoints.mobile} {
    margin: 0;
    display: flex;
  }
`;

const TextStyled = styled(Text)`
  @media ${breakpoints.tablet} {
    line-height: 30px;
    margin-bottom: 16px;
  }

  @media ${breakpoints.mobile} {
    line-height: 42px;
    margin-bottom: 16px;
  }
`;

const HeroText = styled.div`
  padding: 54.03px 1rem 56px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 631px;
  @media ${breakpoints.tablet} {
    padding: 54px 0;
  }

  @media ${breakpoints.mobile} {
    padding 25px 0 32px 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  max-height: 300px;
  height: 100%;
  object-fit: cover;
  margin: auto 0;

  @media ${breakpoints.belowDesktop} {
    display: none;
  }
`;

const StyledLogo = styled.img`
  max-width: 222px;
  max-height: 198px;
  @media ${breakpoints.belowDesktop} {
    display: none;
  }
`;

Hero.defaultProps = {
  backgroundHeroImage: `url(${HeroIlustration})`,
};

Hero.defaultProps = {
  backgroundHeroImage: `url(${HeroIlustration})`,
};
export default Hero;
