import { Box, MultiSelect, Text, TextArea } from "@thrivecoin/ui-components";
import { useContext, useState } from "react";
import { Divider, InputGroup, wrappedSelect } from "../common";
import { ListingEditorContext } from "../ListingEditorContext";
import NewTagBox from "./NewTagBox";
import useTagsByCommunityID from "./useTagsByCommunityID";

const TAGS_FORMATTER = ({ id, name }) => ({ label: name, value: id });

const NameSelectionStep = () => {
  const [showNewTag, setShowNewTag] = useState(false);
  const { isEditing, communities, listing, setState } = useContext(ListingEditorContext);
  const { name, community_id, description, custom_url, community_tag_ids } = listing;
  const options = communities.map(({ id, logo_url, name }) => ({ value: id, label: name, url: logo_url }));
  const { tags, createTag } = useTagsByCommunityID(community_id);
  const tagOptions = tags.map(TAGS_FORMATTER);
  const selectedOptions = tagOptions.filter((item) => community_tag_ids.includes(item.value));

  const onItemClick = (item, event) => {
    setState((listing) => {
      let newState = { ...listing };
      if (listing.community_tag_ids.includes(item.value)) {
        newState.community_tag_ids = listing.community_tag_ids.filter((tag) => tag != item.value);
      } else {
        newState.community_tag_ids.push(item.value);
      }
      return newState;
    });
  };

  const clearSelection = () => setState((listing) => ({ ...listing, community_tag_ids: [] }));

  const toggleNewTag = () => setShowNewTag((prev) => !prev);

  return (
    <div>
      <InputGroup field="name" label="Name (Required)" required defaultValue={name} />
      <InputGroup
        field="custom_url"
        placeholder="E.g. https://twitter.com/thrivecoinhq"
        label="Take Action Link (Optional)"
        description="URL where a contributor can go to complete this contribution"
        defaultValue={custom_url}
      />
      <Divider />
      <InputGroup
        label="Community (Required)"
        field="community_id"
        InputClass={wrappedSelect(options)}
        defaultValue={community_id}
        required
        disabled={isEditing}
      />
      <Divider />
      <InputGroup
        field="description"
        label="Description (Optional)"
        placeholder="E.g. Here’s more details about how to do this activity, and why it’s important for our Community!"
        InputClass={TextArea}
        value={description}
      />
      <Divider />
      <Box $flex $gap="12px" $direction="column" $color="01Primary700">
        <Text $typography="Body/Medium" $bold>Community Tags</Text>
        <Text $typography="Body/Medium">
          Tags allows filtering other ways to contribute based on these tags. You can add a maximum of four tags.
        </Text>
        <MultiSelect
          title="Select Tags"
          options={tagOptions}
          selectedOptions={selectedOptions}
          onItemClick={onItemClick}
          clearSelection={clearSelection}
          $widthContainer="100%"
        >
          <Text $spacing="p-4" $color="03Primary500" onClick={toggleNewTag} $extraBold>
            Add New Tag +
          </Text>
        </MultiSelect>
        {showNewTag && <NewTagBox community_id={community_id} close={toggleNewTag} createTag={createTag} />}
      </Box>
    </div>
  );
};

export default NameSelectionStep;
