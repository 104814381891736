import { Box, Loader, Text } from "@thrivecoin/ui-components";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const options = {
  threshold: 0.1, // Trigger when 10% of the element is visible
};

const LoadMore = ({ pagination, loadMore, extraText, $marginTop = "73px" }) => {
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const unloaded = pagination.total - pagination.page * pagination.per_page;
  const currentPage = +pagination.page;

  const onLoadMore = () => {
    setLoading(true);
    loadMore(currentPage + 1).finally(() => setLoading(false));
  };

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible && !loading) {
      onLoadMore();
    }
  }, [isVisible, loading]);

  let content = "";

  if (unloaded > 0) {
    content = loading ? (
      <Loader />
    ) : (
      <ClickToLoad unloaded={unloaded} $marginTop={$marginTop} onLoadMore={onLoadMore} extraText={extraText} />
    );
  }

  return (
    <Box ref={contentRef} $width="100%" $spacing="mt-6" $flex $alignItems="center" $justifyContent="center">
      {content}
    </Box>
  );
};

const ClickToLoad = ({ unloaded, $marginTop, onLoadMore, extraText }) => {
  if (unloaded == 0) return "";

  return (
    <Content
      $marginTop={$marginTop}
      onClick={onLoadMore}
      $pointer
      $typography="heading/small"
      $underline
      $color="01Primary700"
    >
      Load more {extraText} ({unloaded})
    </Content>
  );
};

const Content = styled(Text)`
  margin-top: ${({ $marginTop }) => $marginTop};
  font-weight: 500;
  text-align: center;
`;

export default LoadMore;
