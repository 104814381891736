import AccountCreatedImage from "@assets/account_created.png";
import ConfetsImage from "@assets/confettis.svg";
import { breakpoints, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import useVirtualPageView from "../../hooks/useVirtualPageView";

const AccountCreatedStep = ({ closeModal }) => {
  useVirtualPageView({ pathname: "/sign-in/success" });
  return (
    <>
      <StyledImage className="confets-image" src={ConfetsImage}></StyledImage>
      <Header $color="02Primary700" $size="28px" $bold center>
        You successfully created your account!
      </Header>

      <StyledText $align="center" $lHeight="24px" $maxWidth="524px" $color="secondary450" $size="16px">
        You can now participate in Community Challenges and earn crypto by contributing to the communities you love!
      </StyledText>

      <StyledImage src={AccountCreatedImage} />
    </>
  );
};

const StyledImage = styled.img`
  width: 100%;
  max-width: 289px;
  max-height: 225px;
  margin: 0 auto;

  @media ${breakpoints.mobile} {
    width: calc(100% - 128px);
  }

  &.confets-image {
    position: absolute;
    top: 10px;
    left: 50px;
    max-width: 120px;

    @media ${breakpoints.mobile} {
      left: 0;
    }
  }
`;

const Header = styled(Text)`
  margin-bottom: 20px;

  @media ${breakpoints.mobile} {
    margin: 0 28px 12px;
  }
`;

const StyledText = styled(Text)`
  margin: auto;

  @media ${breakpoints.mobile} {
    margin: 0 1rem 3rem;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;

  @media ${breakpoints.mobile} {
    width: auto;
    margin: 1rem 1rem 2rem;
  }
`;

const Footer = ({ closeModal }) => {
  return (
    <StyledButton
      $spacing="mt-4"
      variant="primary700"
      $borderRadius="56px"
      $heightSize="56px"
      $size="lg"
      $bold
      onClick={closeModal}
      modal={true}
    >
      Continue
    </StyledButton>
  );
};

AccountCreatedStep.Footer = Footer;
export default AccountCreatedStep;
