import { Box, breakpoints, Button, IconSvg, Input, Modal, Text } from "@thrivecoin/ui-components";
import { referralLink } from "@utils/UrlHelpers";
import { useStoreState } from "easy-peasy";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import styled from "styled-components";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import { trackShare } from "../hooks/useTrackEvents";
import useVirtualPageView from "../hooks/useVirtualPageView";
import MESSAGES from "./messages";

const ShareSocialMediaModal = ({ link: _link, firstContribution, challengeCompleted, hideModal }) => {
  const { user } = useStoreState((state) => state.user);
  const { isMobile } = useStoreState((state) => state.media);
  const defaultLink = referralLink(user.referral_code);
  const link = _link || defaultLink;
  const onCopyClipboard = useCopyToClipboard(link, "Your share link has been copied.");
  let key = "invite";
  key = firstContribution ? "firstContribution" : key;
  key = challengeCompleted ? "challengeCompleted" : key;
  const text = MESSAGES[key];

  const footerContent = <Footer hideModal={hideModal} />;

  const beforeOnClick = (label, callback) => () => {
    trackShare(label);
    callback && callback();
  };

  useVirtualPageView({ pathname: "/share" });

  return (
    <Modal
      open
      onClose={hideModal}
      leaf={true}
      $maxWidth="684px"
      footerContent={footerContent}
      $borderRadius={isMobile && "24px"}
    >
      <StyledHeader $color="02Primary500" $size="28px" $extraBold center $lHeight="36px" $fontFamily="Outfit">
        Invite friends
      </StyledHeader>

      <StyledText $align="center" $lHeight="24px" $maxWidth="524px" $color="02Primary500" $size="16px">
        Everyone contributing to their web3 communities deserves to be recognized for it. Help spread the word about
        ThriveCoin!
      </StyledText>

      <LineDivider />

      <StyledText className="share-txt" $color="02Primary500" $size="16px" $bold>
        Share page link via
      </StyledText>

      <IconsContainer $flex $alignItems="center">
        <FacebookShareButton beforeOnClick={beforeOnClick("Facebook")} url={link} quote={text}>
          <FacebookIcon size="3rem" round />
        </FacebookShareButton>

        <TwitterShareButton beforeOnClick={beforeOnClick("Twitter")} url={link} title={text}>
          <TwitterIcon size="3rem" round />
        </TwitterShareButton>

        <LinkedinShareButton beforeOnClick={beforeOnClick("LinkedIn")} url={link} summary={text}>
          <LinkedinIcon size="3rem" round />
        </LinkedinShareButton>

        <WhatsappShareButton beforeOnClick={beforeOnClick("WhatsApp")} url={link} title={text}>
          <WhatsappIcon size="3rem" round />
        </WhatsappShareButton>

        <TelegramShareButton beforeOnClick={beforeOnClick("Telegram")} url={link} title={text}>
          <TelegramIcon size="3rem" round />
        </TelegramShareButton>
      </IconsContainer>

      <LineDivider />

      <StyledText className="copy-link-txt" $spacing="mt-5 mb-4" $color="02Primary500" $size="16px" $bold>
        Or copy link to post
      </StyledText>
      <Box $flex $alignItems="center" $relative>
        <StyledInput disabled defaultValue={link} $bold />
        <IconBox $flex $alignItems="center" onClick={beforeOnClick("Personal link", onCopyClipboard)}>
          <IconSvg $width="14px" $color="hover" icon="copy" />
          <Text $color="hover" $extraBold $spacing="ml-2">
            Copy
          </Text>
        </IconBox>
      </Box>
    </Modal>
  );
};

const Footer = ({ hideModal }) => {
  return (
    <Box $align="center">
      <Button
        $bold
        $size="lg"
        $height="56px"
        $borderRadius="40px"
        onClick={hideModal}
        $color="02Primary500"
        kind="outline"
        rightIcon="right-long"
        iconWidth="20px"
      >
        Keep Contributing
      </Button>
    </Box>
  );
};

const IconBox = styled(Box)`
  position: absolute;
  right: 15px;
  cursor: pointer;

  @media ${breakpoints.mobile} {
    right: 30px;
  }

  &::before {
    content: "";
    width: 1px;
    height: 12px;
    background-color: ${({ theme }) => theme.colors.secondary200};
    position: absolute;
    left: -20px;
  }
`;

const LineDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey150};
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const StyledHeader = styled(Text)`
  margin:0 0 1.5rem;

  @media ${breakpoints.mobile} {
    margin: 0 0 1rem;
  }
`;

const StyledText = styled(Text)`
  margin: 0 0 1.5rem;

  @media ${breakpoints.mobile} {
    margin: 0 1rem 1.5rem;
  }

  &.share-txt {
    margin: 1.5rem 0;

    @media ${breakpoints.mobile} {
      margin: 1.5rem 1rem 1rem;
    }
  }

  &.copy-link-txt {
    margin: 1.5rem 0 1rem;

    @media ${breakpoints.mobile} {
      margin: 1.5rem 1rem 1rem;
    }
  }
`;

const IconsContainer = styled(Box)`
  margin: 0 0 1.5rem;
  gap: 15px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem 1.5rem;
    gap: 12px;
  }
`;

const StyledInput = styled(Input)`
  & input {
    padding-right: 117px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media ${breakpoints.mobile} {
    margin: 0 1rem;
  }
`;

ShareSocialMediaModal.defaultProps = {
  showReturn: true,
};

export default ShareSocialMediaModal;
