import Hand from "@assets/profile/hand.svg";
import { Box, breakpoints, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { CONFIG } from "../SocialButton";

const LOCAL_CONFIG = {
  ...CONFIG,
  zoom: { $bgColor: "zoom", rightIcon: "zoom2" },
  email: { $bgColor: "email", rightIcon: "email" },
  linkedin: { $bgColor: "linkedin", rightIcon: "linkedin2" },
  bankless: { $bgColor: "banklessDAO", rightIcon: "bankless" },
  apecoin: { $bgColor: "linkedin", rightIcon: "ape" },
};

const SocialButtonCard = ({ className, isConnected, children, count, displayName, onClick, $isModal }) => {
  const networkName = displayName?.split(" ")[0].toLowerCase();
  const { rightIcon, $bgColor, ...config } = LOCAL_CONFIG[networkName] || {};
  const mainBoxBgColor = isConnected ? "03Primary500" : $isModal ? "02PrimaryGrey100" : "01Primary110";
  const btnColor = isConnected ? "error" : $isModal ? "02Primary0" : "01Primary0";
  const showCount = count > 0;
  const isApeCoin = networkName === "apecoin";
  const titleColor = $isModal ? (isConnected ? "02Primary0" : "02Primary500") : "01Primary700";

  return (
    <MainBox $isModal={$isModal} $flex $direction="column" $justifyContent="space-between" $bgColor={mainBoxBgColor}>
      <Box $flex $justifyContent="space-between">
        <Box $flex $gap="12px" $alignItems="center">
          <IconWrapper $bgColor={$bgColor || networkName}>
            {rightIcon && (
              <>
                {isApeCoin ? (
                  <IconSvg icon={rightIcon} $color="02Primary0" $width="40px" $height="40px" />
                ) : (
                  <IconSvg icon={rightIcon} $color="02Primary0" />
                )}
              </>
            )}
          </IconWrapper>
          <NetworkText $typography="body/medium" $color={titleColor} $maxWidth="160px" $bold>
            {displayName}
          </NetworkText>
        </Box>
        {isConnected && <CheckIcon icon="circle-check" $color="white" $width="32px" />}
      </Box>
      {isConnected && <HandImg src={Hand} />}

      {showCount &&
        (!isConnected ? (
          <CountText $typography="body/small" $align="center" $bold>
            Unlocks {count} more ways to contribute
          </CountText>
        ) : (
          <ConnectedText $typography="body/small" $align="left" $bold>
            Bravo! You unlocked {count} ways to contribute!
          </ConnectedText>
        ))}
      <Button
        $bold
        $height="44px"
        $borderRadius="40px"
        kind="socials"
        className={className}
        {...config}
        leftIcon={isConnected ? "disconect" : "chain"}
        $bgColor={isConnected ? "02Primary0" : "03Primary500"}
        $borderColor={isConnected && "02Primary0"}
        onClick={onClick}
        $color={btnColor}
      >
        {children}
      </Button>
    </MainBox>
  );
};

const MainBox = styled(Box)`
  border: 1px solid ${({ theme, $isModal }) => theme.colors[$isModal ? "02PrimaryGrey150" : "01Primary100"]};
  background: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  ${({ $isModal }) => !$isModal && "max-width: 287.76px;"}
  height: 240px;
  border-radius: 12px;
  padding: 20px 20px 24px;
  position: relative;
  box-sizing: border-box;

  @media ${breakpoints.mobile} {
    max-width: unset;
  }
`;

const IconWrapper = styled(Box)`
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const NetworkText = styled(Text)`
  text-transform: capitalize;
`;

const CheckIcon = styled(IconSvg)`
  path {
    fill: white;
  }
`;

const CountText = styled(Text)`
  height: 42px;

  @media ${breakpoints.mobile} {
    text-align: left;
    max-width: 220px;
  }
`;

const ConnectedText = styled(CountText)`
  margin-left: auto;
  max-width: 169px;

  @media ${breakpoints.tablet} {
    max-width: 146px;
    margin-left: 76px;
    text-align: left;
  }
`;

const HandImg = styled.img`
  position: absolute;
  top: 60px;
  left: 0;
`;

export default SocialButtonCard;
