import { Modal } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import ListingEditor from "../ListingEditor";

const ListingEditorModal = ({ hideModal, listingID, communityID }) => {
  const { isMobile } = useStoreState((state) => state.media);
  return (
    <Modal
      open
      hideX
      $width="100%"
      $paddingBody="0!important"
      $transform="unset"
      onClose={hideModal}
      $maxWidth="calc(100vw - 64px)"
      $contentHeight="calc(100vh - 64px)"
      $borderRadius="12px"
      $bgColor={isMobile ? "background" : "01Surface"}
    >
      <ListingEditor communityID={communityID} listingID={listingID} />
    </Modal>
  );
};

export default ListingEditorModal;
