import { BadgeCard } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoaderTable from "../../CommunityPage/Challenges/Contributions/LoaderTable";
import HeaderRow from "./HeaderRow";

const TableBadges = ({ data, onSort, sortType, sortDirection, loading, _getBadges }) => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  const navigate = useNavigate();

  const { isMobile } = useStoreState((state) => state.media);
  const doCommunity = (event) => {
    const id = event.target.dataset.id;
    navigate(`/communities/${id}`);
  };
  const doContributors = (event) => {
    const id = event.target.dataset.id;
    navigate(`/communities/${id}?tabIndex=3`);
  };

  const doEdit = (event) => {
    const id = event.target.dataset.id;
    showModal({ modalName: "AddBadgeModal", id: id, callback: _getBadges });
  };

  const doDelete = (event) => {
    const id = event.target.dataset.id;
    showModal({ modalName: "DeleteBadgeModal", id: id, callback: _getBadges });
  };

  return (
    <>
      <Table>
        {!isMobile && (
          <HeaderRow onSort={onSort} sortType={sortType} sortDirection={sortDirection} community_name={true} />
        )}
        {!loading && (
          <tbody>
            {data?.map((item) => (
              <>
                <BadgeCard
                  key={item.id}
                  doCommunity={doCommunity}
                  doContributors={doContributors}
                  doEdit={doEdit}
                  doDelete={doDelete}
                  {...item}
                />
                <Spacing />
              </>
            ))}
          </tbody>
        )}
      </Table>
      <LoaderTable loading={loading} />
    </>
  );
};

export const Table = styled.table`
  width: 100%;
  margin-top: 32px;
  position: relative;
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  th {
    padding: 0 24px 12px 24px;
  }
`;

const Spacing = styled.tr`
  display: block;
  margin-bottom: 20px;
`;

export default TableBadges;
