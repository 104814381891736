import toFormData from "@utils/toFormData";
import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("badges");
const communityRequester = new HttpWrapper("communities");

const attributes = () => ({
  badges: [],
  communityBadges: [],
  editBadge: {},
});

const actions = {
  createBadge: thunk((_, { data, community_id }) => {
    const badge = toFormData("badge", data);
    const request = community_id
      ? communityRequester.post(`${community_id}/create_badge`, badge)
      : requester.post("", badge);
    return request;
  }),
  getEditBadge: thunk(({ setEditBadge }, id) => {
    return requester.get(`${id}/edit`).then(({ badge }) => setEditBadge(badge));
  }),
  updateBadge: thunk((actions, { data }) => {
    const url = `/${data.id}`;
    const badge = toFormData("badge", data);

    return requester.patch(url, badge);
  }),
  getBadges: thunk(
    ({ setBadges }, { sort_type = "name", sort_direction = "desc", keyword = "", community_ids, system }) => {
      const params = { sort_type, sort_direction, community_ids, system };
      if (keyword) {
        params.keyword = keyword;
      }
      return requester.get("", params).then(({ badges }) => {
        setBadges(badges);
      });
    }
  ),
  getCommunityBadges: thunk(
    ({ setCommunityBadges }, { community_id, sort_type = "name", sort_direction = "desc", keyword = "" }) => {
      const params = { sort_type, sort_direction };
      if (keyword) {
        params.keyword = keyword;
      }
      return communityRequester.get(`${community_id}/badges`, params).then(({ badges }) => {
        setCommunityBadges(badges);
      });
    }
  ),
  deleteBadge: thunk((actions, id) => {
    const url = `/${id}`;

    return requester.delete(url);
  }),
  setBadges: action((state, badges) => {
    state.badges = badges;
  }),
  setCommunityBadges: action((state, communityBadges) => {
    state.communityBadges = communityBadges;
  }),
  setEditBadge: action((state, badge) => {
    state.editBadge = badge;
  }),
};

const badges = {
  ...attributes(),
  ...actions,
};

export default badges;
