import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import WalletCard from "./WalletCard";
const LIMIT = 2;

const AdditionalWallets = ({ wallets, disconnectWallet, getExtraProvider, loading, length }) => {
  const { isTablet, isDesktop, isMobile } = useStoreState((state) => state.media);
  const width = isTablet ? "265px" : "376px";
  const buttonWidth = isTablet ? "265px" : "100%";
  const missingWallets = Math.max(LIMIT - length, 0);
  const fillers = new Array(missingWallets).fill(1, 0, missingWallets);

  return (
    <Box>
      <Text $typography="heading/large" $bold $spacing="mb-4">
        Additional Wallets
      </Text>
      <Text $maxWidth={isDesktop ? "627px" : "553px"} $spacing="mb-6" $typography="body/medium">
        You can verify up to <b>three additional wallets</b> to ensure contributions you make across different wallet
        addresses are seen and rewarded.
      </Text>

      <WrapperWallets>
        {wallets.map((wallet, index) => (
          <WalletCard
            key={wallet.id}
            {...wallet}
            disconnect={disconnectWallet}
            smallCard={true}
            compact
            title={"Additional Wallet " + ++index}
            $width={width}
            $height="205px"
          />
        ))}

        {wallets.length < 3 && (
          <Box>
            <WalletCard
              key={2}
              smallCard
              compact
              detectedWallet
              notConnected
              $height="205px"
              $bgColorCard="02PrimaryGrey500"
              title={"Additional Wallet " + ++length}
              $width={width}
            />
            <Button
              $spacing="mt-5"
              $borderRadius="40px"
              leftIcon="plus"
              $size="md"
              $width={buttonWidth}
              onClick={getExtraProvider}
              loading={loading}
            >
              Connect Wallet Address
            </Button>
          </Box>
        )}
        {fillers.map((_, index) => (
          <Box key={index}>
            <WalletCard
              smallCard
              title="Additional Wallet"
              $bgColorCard="01Primary110"
              disableConnect={true}
              $width={width}
              $height="205px"
            />
            <DisabledButton
              $spacing="mt-5"
              $width={buttonWidth}
              $borderRadius="40px"
              $bgColor="01Primary110"
              $borderColor="01Primary110"
              $color="01Primary400"
              leftIcon="plus"
              $hoverBackground="01Primary110"
              $hoverBorderColor="01Primary110"
              $hoverColor="01Primary400"
              $size="md"
            >
              Connect Wallet Address
            </DisabledButton>
          </Box>
        ))}
      </WrapperWallets>
      {isMobile && <Box $height="1px" $spacing="mt-6" $bgColor="01Primary100"/>}
    </Box>
  );
};

const WrapperWallets = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 55px;
  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const DisabledButton = styled(Button)`
  cursor: default;
`;
export default AdditionalWallets;
