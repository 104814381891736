import { Box, breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import ImportantNoticeStep from "./ImportantNotice";
import RequestSecurityReviewStep from "./RequestSecurityReview";

export const STEP_INDEXES = {
  ImportantNoticeStep: 0,
  RequestSecurityReviewStep: 1,
};

const STEP_KLASSES = [ImportantNoticeStep, RequestSecurityReviewStep];

const MoreDetailsModal = ({ hideModal, initialStep, afterClose, type, user, ...props }) => {
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { createRequestReview } = useStoreActions((actions) => actions.userAudits);
  const { refresh } = useStoreActions((actions) => actions.authentication);

  const [loading, setLoading] = useState(false);
  const { id, image, username } = user;
  const [message, setMessage] = useState("");
  const initialIsReviewRequested =
    user?.banned_user_audit?.review_requested || user?.restricted_user_audit[0]?.review_requested;

  const [isReviewRequested, setIsReviewRequested] = useState(initialIsReviewRequested);

  const [step, goToStep] = useState(initialStep);
  const StepKlass = STEP_KLASSES[step];

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const goBack = () => goToStep((step) => step - 1);

  const closeModal = () => {
    hideModal();
    afterClose && afterClose();
  };

  const data = {
    message,
    user_audit_id: type === "banned" ? user.banned_user_audit?.id : user.restricted_user_audit[0]?.id,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    return createRequestReview(data)
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        afterClose && afterClose();
        hideModal();
      }).then(refresh)
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  useEffect(() => {
    setIsReviewRequested(initialIsReviewRequested);
  }, [user]);

  console.log(user);
  const footerContent = StepKlass.Footer ? (
    <StepKlass.Footer
      loading={loading}
      goToStep={goToStep}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      goBack={goBack}
      isReviewRequested={isReviewRequested}
    />
  ) : null;

  return (
    <form onSubmit={handleSubmit}>
      <StyledModal
        open
        onClose={hideModal}
        footerContent={footerContent}
        maxWidth="620px"
        paddingBody="16px 48px 48px 48px"
      >
        <StyledBox $direction="column" textAlign="center">
          <Header type={type} image={image} username={username} />
          <StepKlass
            goToStep={goToStep}
            closeModal={closeModal}
            type={type}
            user={user}
            handleMessageChange={handleMessageChange}
            {...props}
          />
        </StyledBox>
      </StyledModal>
    </form>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;

  ${Modal.Body} {
    padding-bottom: 0;
    overflow: initial;
  }

  ${Modal.Footer} {
    padding-top: 24px;
    padding-bottom: 48px;

    & > div {
      padding: 0 48px;
    }
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

MoreDetailsModal.defaultProps = {
  initialStep: 0,
};

export default MoreDetailsModal;
