import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useState } from "react";

const ConfirmDeletionModal = ({ hideModal, action, title, confirmText, rejectText, warning }) => {
  const [loading, setLoading] = useState(false);

  const actionClicked = (e) => {
    setLoading(true);
    action(e)
      .then(hideModal)
      .catch(() => setLoading(false));
  };

  return (
    <Modal open onClose={hideModal} $maxWidth="684px">
      <Text $bold $color="02Primary500" $typography="heading/medium" $spacing="mb-4" $textAlign="center">
        {title}
      </Text>
      <Text $typography="body/medium" $textAlign="center">
        {warning}
      </Text>
      <Box $spacing="mt-6" $flex $direction="row" $gap="16px">
        <Button
          loading={loading}
          onClick={hideModal}
          $grow={2}
          $borderRadius="40px"
          $color="02Primary500"
          $bgColor="white"
          $borderColor="02PrimaryGrey300"
        >
          {rejectText}
        </Button>

        <Button loading={loading} onClick={actionClicked} $borderRadius="40px" $grow={1} $bgColor="02Secondary500">
          {confirmText}
        </Button>
      </Box>
    </Modal>
  );
};

ConfirmDeletionModal.defaultProps = {
  title: "Delete",
  confirmText: "Confirm",
  rejectText: "Cancel",
};

export default ConfirmDeletionModal;
