import { Modal } from "@thrivecoin/ui-components";
import SeasonEditor from "../SeasonEditor";
import { useStoreState } from "easy-peasy";

const SeasonEditorModal = ({ hideModal, seasonID, communityID, edit }) => {
  const { isMobile } = useStoreState((state) => state.media);
  return (
    <Modal
      open
      hideX
      $width="100%"
      $paddingBody="0!important"
      $transform="unset"
      onClose={hideModal}
      $maxWidth="calc(100vw - 64px)"
      $contentHeight="calc(100vh - 64px)"
      $borderRadius="12px"
      $bgColor={isMobile ? "background" : "01Surface"}
      $overflowY="hidden"
    >
      <SeasonEditor communityID={communityID} seasonID={seasonID} edit={edit} />
    </Modal>
  );
};

export default SeasonEditorModal;
